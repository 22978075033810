<template>
  <div class="login-container">
    <el-form :model="data.myForm" :rules="data.myRule" @keyup.enter="handleSubmit"
             status-icon
             ref="myForm"
             label-position="left"
             label-width="0px"
             class="demo-ruleForm login-page">
      <h3 class="title">登录</h3>
      <el-form-item prop="username">
        <el-input type="text"
                  v-model="data.myForm.username"
                  auto-complete="off"
                  placeholder="用户名"
        />
      </el-form-item>
      <el-form-item prop="password">
        <el-input type="password"
                  v-model="data.myForm.password"
                  auto-complete="off"
                  placeholder="密码"
        />
      </el-form-item>
      <el-form-item style="width:100%;">
        <el-button type="primary" style="width:100%;" @click="handleSubmit">登录</el-button>
      </el-form-item>
      <el-form-item style="width:100%;">
        <el-button type="success" style="width:100%;" @click="toRegister">去注册</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import {api} from "@/api";
import {getCurrentInstance, reactive} from "vue";
import {router} from "@/router";
import {utils} from "@/utils";

export default {
  name: "login",
  setup() {
    const ctx = getCurrentInstance()
    const data = reactive({
      myForm: {
        username: undefined,
        password: undefined,
      },
      myRule: {
        username: [{required: true, message: '请输入账号', trigger: 'blur'}],
        password: [
          {required: true, message: '请输入密码', trigger: 'blur'},
          { min: 6, message: '密码长度大于6个字符', trigger: 'blur' }
        ]
      }
    })
    const methods = {
      toRegister: () => {
        router.push({ path: 'register'})
      },
      handleSubmit () {
        ctx.refs.myForm.validate((valid) => {
          if (valid) {
            api.common.login(data.myForm.username, data.myForm.password).then(res => {
              if (res.code === 0) {
                localStorage.setItem("manage-token", res.data.token);
                utils.message.success("登录成功")
                router.push({ path: 'project'})
              }else {
                utils.message.error(res.msg)
              }
            })
          }else{
            console.log('error submit!');
            return false;
          }
        })
      }
    }
    return {
      data,
      ...methods
    }
  }
};
</script>

<style>
.login-container {
  width: 100%;
  height: 100%;
}
.login-page {
  -webkit-border-radius: 5px;
  border-radius: 5px;
  margin: 180px auto;
  width: 350px;
  padding: 35px 35px 15px;
  background: #fff;
  border: 1px solid #eaeaea;
  box-shadow: 0 0 25px #cac6c6;
}
label.el-checkbox.rememberme {
  margin: 0px 0px 15px;
  text-align: left;
}
</style>
